import React, {useEffect} from "react"
import Header from '../components/header'
import Container from '../components/container'
import * as Styles from "./contact.module.css"
import Layout from "../components/layout"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"

const ContactPage = () => {
    const contact = React.createRef()

    useEffect(() => {
        const setHeight = () => {
            contact.current.style.height = window.innerHeight + "px"
        };
        window.addEventListener("resize", setHeight);

        setHeight();
    }, [])

    return (
        <Layout color={'dark'}>
            <Helmet>
                <title>Contact Tom Laker</title>
                <meta name="description" content="For business or general enquiries, contact Tom at hello@tomlaker.com or +4620418228." />
            </Helmet>
            <div className={Styles.container} ref={contact}>
                <Header light={true} />
                <Container>
                    <div className={Styles.mainHeading}>say hello</div>
                </Container>
                <Footer unfixed={true} />
            </div>
        </Layout>
    )
}

export default ContactPage